.wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.heading{
    color: 'black';
    font-size: 28px;
    font-weight: bold;
}
.button-container{
    border: 1px solid blue;
    background-color: blue;
    border-radius: 4px;
    padding: 4px;
}

.notification-card:hover{
    background-color: #f2f2f2;
}