
.container-bottombar{
    bottom: 2px;
    right: 0px;
    left : 0px;
    position: fixed;
    z-index: 2;
    background-color: azure;
    width: 100%;
    width: -webkit-fill-available;
    padding-right: 0px;
    height : 60px;
    padding-left: 4px;
} 
.close-cross{
    float: right;
    margin-top: -15px;
    box-sizing: border-box;
} 

.media-scroller{
    display: flex;
    flex-direction: row;
    margin-top: 0px;
    overflow-x: auto;
    height: inherit;
    overflow-y: hidden;
}
.media-element{
    display: flex;
    flex-direction: row;
    width: max-content;
    cursor: pointer;
}
[data-letters]:before {
    content:attr(data-letters);
    display:inline-block;
    font-size:1em;
    font-style:normal;
    width:34px;
    height:32px;
    line-height:2.5em;
    text-align:center;  
    border-radius:50%;
    background:#592B7E;
    vertical-align:middle;
    margin-right:1em;
    color:white;
    }
.ul {
  inline-size: 100%;
  aspect-ratio: 16 / 9;
  object-fit: cover;
  list-style-type: none;
  margin-top: 8px;
}
/* general styling */