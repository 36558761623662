/*body {*/
/*  margin: 0;*/
/*  padding: 0;*/
/*  font-family: sans-serif;*/
/*}*/

h4 {
  font-family: "Georgia", serif !important;
  font-size: 18px;
  font-weight: 500;
}
.shortlist-panel-overall {
  margin-top: 20px;
}
.shortlist-heading-1 {
  margin: 10px 0px;
}

.shortlist-heading-2 {
  margin: 14px;
}
.floating-button {
  position: fixed;
  bottom: 14%;
  right: 10px;
  z-index: 100;
  cursor: pointer;
}

.rmdp-input {
  border: 1px solid #c0c4d6;
  border-radius: 5px;
  height: 22px;
  margin: 1px 0;
  padding: 18px 15px;
}

.floating-button button {
  background: #592b7e;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  width: 56px;
  height: 56px;
  border-radius: 30px;
  font-size: 24px;
  padding: 11px;
  color: #ffffff;
  text-align: center;
}

.have-us-call-you-stats-tag {
  background-color: #329ea8;
  border-color: #329ea8;
  color: #ffffff;
  width: max-content;
  padding: 4px;
  border-radius: 4px;
  font-weight: 600 !important;
  margin-bottom: 5px;
}

.shortlist-heading-black {
  font-size: 15px;
  font-weight: 600;
  color: black;
  padding: 8px;
  cursor: pointer;
}

.shortlist-heading-blue {
  font-size: 15px;
  font-weight: 600;
  color: #03a9f4;
  padding: 8px;
  cursor: pointer;
}
.click-shortlist-venue {
  cursor: pointer;
  margin-bottom: 10px;
}

.display-flex-shortlist {
  display: flex;
  align-items: center;
}

.mright-1 {
  margin-right: 12px;
}
.tags {
  /* width:fit-content!important; */
  border: 1px solid #03a9f4;
  padding: 4px;
  margin-right: 12px;
  color: white;
  background-color: #03a9f4;
  border-radius: 8px;
  font-weight: 700;
}

.tags-rec-venues {
  border: 1px solid grey;
  padding: 6px;
  margin: 0px 4px 0px 2px;
  color: black;
  border-radius: 8px;
  background-color: #d3d3d3;
}
.tags-rec-venues-veg {
  border: 1px solid grey;
  padding: 6px;
  margin: 0px 4px 0px 2px;
  color: black;
  border-radius: 8px;
  background-color: #90ee90;
}
.tags-rec-venues-non-veg {
  border: 1px solid grey;
  padding: 6px;
  margin: 0px 4px 0px 2px;
  color: black;
  border-radius: 8px;
  background-color: #ffcccb;
}
.recommended-box {
  margin: 20px;
}
.space-right {
  margin-right: 3px;
}

.margin-heading {
  margin-left: 14px;
}

.zero-padding {
  padding: 0px;
}

.missing-details-padding {
  padding: 14px !important;
}

.radio-content-styling {
  padding: 0px 0px 0px 4px;
}

.font-weigth-details {
  font-weight: 600;
}
.width-content {
  width: auto;
}

.switch-template {
  padding: 10px;
  cursor: pointer;
}

.vm-modal-z-index {
  z-index: 1050;
}

.vm-navbar-z-index {
  z-index: 500;
}

.vm-inside-sales {
  padding: 2px 6px;
  border: 2px solid green;
}

.vm-visit-sales {
  padding: 2px 6px;
  border: 2px solid red;
}

.vm-btn-xs {
  font-size: 12px;
  line-height: 12px;
  padding: 0px 6px;
}
